import './utils/addSvgSprite.js';
import './utils/polyfills.js';
import './utils/loadingScreen.js'

import { openHeaderMenu } from './modules/initBurger.js';
import { languageChange } from './modules/initLangChange.js';
import { initModals } from './modules/initModal.js';
import { initSideNav } from './modules/initSideNav.js';
import { initPage } from './modules/accessControl.js';
import { renderRecsData } from './modules/recs/renderRecsData.js';
import { initShopsMap, postShopId } from './modules/shops/getShopsList.js';
import { initPhotoUpload } from './modules/photo/uploadPhoto.js';
import { getResults } from './modules/result/getResults.js';
import { initSharing } from './modules/result/shareResults.js';
import { initComparePhoto } from './modules/recs/initComparePhoto.js';
import { showScreen } from './modules/photo/initPhotoScreens.js';
import { initAdditSlider, initEveningSlider, initMorningSlider } from './modules/recs/initRecsSliders.js';
import { updateTestElements } from './modules/test/updateTestElements.js';
import { setProductDetails } from './modules/recs/getProductDetails.js';
import { renderUserTestsData } from './modules/user/getUserTestsList.js';
import { getUserToken } from './modules/user/getUserToken.js';
import { initLogin } from './modules/login/initLogin.js';
import { renderOrderData } from './modules/order/renderOrderData.js';
import { checkBottomMenu } from './utils/isBottomMenu.js';
import { getTestResult } from './modules/user/getUserTestResult.js';
import { getTestData } from './modules/test/manageTestData.js';

document.addEventListener('DOMContentLoaded', async () => {
  if (!window.initialized) {
    window.initialized = true;

    checkBottomMenu()
    initLogin()
    initPage();
    // openHeaderMenu();
    languageChange();
    updateTestElements()
    initModals();
    initSideNav();
    initPhotoUpload();
    initShopsMap();
    postShopId()
    initSharing()
    initComparePhoto()

    if (window.location.pathname.includes('index.html')) {
      await getUserToken()
    } if (window.location.pathname.includes('photo.html')) {
      await showScreen(0);
    } else if (window.location.pathname.includes('test.html')) {
      await getTestData()
    } else if (window.location.pathname.includes('profile-tests.html')) {
      await renderUserTestsData()
    } else if (window.location.pathname.includes('profile-result.html')) {
      await getTestResult()
    } else if (window.location.pathname.includes('results.html')) {
      await getResults('usual', '');
    } else if (window.location.pathname.includes('recs.html')) {
      await renderRecsData();
      initMorningSlider()
      initEveningSlider()
      initAdditSlider();
      initModals();
      setProductDetails()
    } else if (window.location.pathname.includes('order.html')) {
      await renderOrderData();
    }
  }
});

import {
  hideLoadingOverlay,
  showLoadingOverlay,
} from "../../utils/loadingScreen";
import { BASE_URL, postData } from "../api";
import { modalStack } from "../initModal";
import { getBasket, updateBasketState } from "../order/getOrderBasket";
import { getRecsData } from "./getRecsData";
import { Counter } from "./manageRecsBasket";
import { getBrandsList } from "./recsFilter";

const morningCare = document.querySelector("[data-recs-morning]");
const eveningCare = document.querySelector("[data-recs-evening]");
const additionalCare = document.querySelector("[data-recs-addit]");

let originalProductCode = null;
let stage = null;

class AlternativeProductCard {
  constructor(code, img, itemName, price, stage, parentElement, ...classes) {
    this.code = code;
    this.img = img;
    this.itemName = itemName;
    this.classes = classes;
    this.parent = parentElement;
    this.price = price;
    this.stage = stage;
  }

  render() {
    const card = document.createElement("li");
    card.classList.add("modal__alter-card");
    card.innerHTML = `
      <div class="card__prod" data-product-code=${this.code} data-stage=${this.stage}>
        <div class="card__prod-cont">
          <img src=${this.img} alt="Изображение продукта.">
          <div class="card__prod-text">
            <p class="card__prod-name">${this.itemName}</p>
            <span class="card__prod-price">${this.price} ₽</span>
          </div>
        </div>
        <div class="card__prod-btns">
          <button type="button" class="card__change btn btn--black" data-recs-change>Заменить</button>
        </div>
      </div>
    `;
    if (this.parent && typeof this.parent.append === "function") {
      this.parent.append(card);
    } else {
      console.error("Parent element is not valid:", this.parent);
    }
  }
}

class ProductCard {
  constructor(
    name,
    description,
    code,
    img,
    sub_name,
    itemName,
    price,
    stage,
    parentElement,
    index,
    list_products,
    ...classes
  ) {
    this.name = name;
    this.description = description;
    this.code = code;
    this.img = img;
    this.sub_name = sub_name;
    this.itemName = itemName;
    this.classes = classes;
    this.parent = parentElement;
    this.price = price;
    this.stage = stage;
    this.index = index;
    this.list_products = list_products;
  }

  render() {
    const card = document.createElement("li");
    card.classList.add("recs__card", "card", "swiper-slide");
    card.innerHTML = `
      <h3>Этап ${this.index}: ${this.name}</h3>
      <p class="card__name">${this.description}</p>
      <div class="card__prod" data-recs-prod data-product-code=${this.code} data-stage=${this.stage}>
        <div class="card__prod-cont">
          <img src=${this.img} alt="Изображение продукта.">
          <div class="card__prod-text">
            <span class="card__prod-label">${this.sub_name}</span>
            <p class="card__prod-name">${this.itemName}</p>
            <span class="card__prod-price">${this.price} ₽</span>
          </div>
        </div>
        <div class="card__prod-btns">
          <button type="button" class="card__details js-modal" data-modal="details">Подробнее</button>
          <button type="button" class="card__details card__alter js-modal" data-modal="alter">Заменить</button>
        </div>
        <div class="card__counter" data-recs-counter>
          <button type="button" class="card__add btn btn--black" data-recs-add>В корзину
            <svg width="20" height="20" aria-hidden="true">
              <use xlink:href="#recs-basket"></use>
            </svg>
          </button>
          <div class="card__counter-wrapper" data-recs-counter-cont>
            <button type="button" class="card__decr" data-recs-decr>
              <svg width="24" height="24" aria-hidden="true">
                <use xlink:href="#recs-minus"></use>
              </svg>
            </button>
            <input readonly type="number" class="card__input" data-recs-counter-input name="" value="0">
            <button type="button" class="card__incr" data-recs-incr>
              <svg width="24" height="24" aria-hidden="true">
                <use xlink:href="#recs-plus"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    `;
    if (this.parent && typeof this.parent.append === "function") {
      this.parent.append(card);
    }

    const alterButton = card.querySelector(".card__alter");
    if (alterButton) {
      alterButton.addEventListener("click", () => {
        renderAlternativeCards(this.list_products.slice(1, 4));
      });
    }

    const replaceButton = card.querySelector("[data-recs-change]");
    if (replaceButton) {
      replaceButton.addEventListener("click", () => {
        const stage = card.querySelector("[data-stage]").dataset.stage;
        const productCode = card.querySelector("[data-product-code]").dataset
          .productCode;
        replaceProduct(stage, productCode);
      });
    }
  }
}

function renderAlternativeCards(alternativeProducts) {
  const alterList = document.querySelector("[data-alter-list]");
  alterList.innerHTML = "";

  if (!Array.isArray(alternativeProducts) || alternativeProducts.length === 0) {
    alterList.innerHTML = `<p class="no-alternatives">Альтернативных продуктов не найдено</p>`;
    return;
  }

  alternativeProducts.forEach((product) => {
    const { code, img, price, stage } = product;
    const alternativeProductCard = new AlternativeProductCard(
      code,
      img,
      product.name,
      price,
      stage,
      alterList
    );
    alternativeProductCard.render();
  });
}

function getUserPhoto(beforeFace, afterFace) {
  const beforePhotoCont = document.querySelector("[data-recs-before]");
  const afterPhotoCont = document.querySelector("[data-recs-after]");

  if (beforeFace && beforePhotoCont) {
    beforePhotoCont.src = beforeFace;
  }

  if (afterFace && afterPhotoCont) {
    afterPhotoCont.src = afterFace;
  }
}

function renderSingleProductCard(
  product,
  index,
  renderedBrands,
  parentElement
) {
  const { name, description, sub_name, list_products } = product;

  if (list_products && list_products.length > 0) {
    const firstProduct = list_products[0];
    if (firstProduct) {
      const { brand, code, img, name: itemName, price, stage } = firstProduct;
      renderedBrands.add(brand);

      if (parentElement) {
        const productCard = new ProductCard(
          name,
          description,
          code,
          img,
          sub_name,
          itemName,
          price,
          stage,
          parentElement,
          index,
          list_products
        );
        productCard.render();
      }
    }

    list_products.slice(1).forEach((alternativeProduct) => {
      const { brand } = alternativeProduct;
      renderedBrands.add(brand);
    });
  }
}

function renderProductCards(products, stepsToStages) {
  const renderedBrands = new Set();
  const groupedProducts = {
    morningCare: [],
    eveningCare: [],
    additionalCare: [],
  };

  const productsMap = {};
  products.forEach((product) => {
    productsMap[product.key] = product;
  });

  function addProductsToGroup(stepKeys, group, products) {
    stepKeys.forEach((key) => {
      const product = products.find((p) => p.id === key);
      if (product) {
        group.push(product);
      } else {
        console.warn(`Продукт с ключом ${key} не найден.`);
      }
    });

    group.sort((a, b) => (a.order || 999) - (b.order || 999));
  }

  addProductsToGroup(
    stepsToStages["Утренний уход"],
    groupedProducts.morningCare,
    products
  );
  addProductsToGroup(
    stepsToStages["Вечерний уход"],
    groupedProducts.eveningCare,
    products
  );
  addProductsToGroup(
    stepsToStages["Дополнительный уход"],
    groupedProducts.additionalCare,
    products
  );

  renderGroupedProducts(groupedProducts.morningCare, morningCare, renderedBrands, "Утренний уход");
  
  renderGroupedProducts(groupedProducts.eveningCare, eveningCare, renderedBrands, "Вечерний уход");
  
  renderGroupedProducts(groupedProducts.additionalCare, additionalCare, renderedBrands, "Дополнительный уход");
  

  updateStepCount("[data-recs-morning]", groupedProducts.morningCare.length);
  updateStepCount("[data-recs-evening]", groupedProducts.eveningCare.length);
  updateStepCount("[data-recs-addit]", groupedProducts.additionalCare.length);

  getBasket().then((basketInfo) => {
    updateBasketState(basketInfo);

    document.querySelectorAll("[data-recs-prod]").forEach((prod) => {
      new Counter(prod);
    });
  });

  return renderedBrands;
}

function updateStepCount(selector, count) {
  const heading = document
    .querySelector(`${selector}`)
    .closest(".recs__care-wrapper")
    ?.querySelector("h2");
  if (heading) {
    heading.textContent = `${heading.textContent.trim()} (${count})`;
  }
}

function renderGroupedProducts(products, parentElement, renderedBrands, stageType) {
  if (!stageType) {
    console.error("Ошибка! stageType не передан!", products);
    return;
  }
  
  const sortedProducts = products
    .filter(item => item.list_products && item.list_products.length > 0)
    .sort((a, b) => {
      const indexA = a.stage_type.indexOf(stageType);
      const indexB = b.stage_type.indexOf(stageType);

      const orderA = (Array.isArray(a.order) && a.order.length > indexA && indexA !== -1) ? a.order[indexA] : 999;
      const orderB = (Array.isArray(b.order) && b.order.length > indexB && indexB !== -1) ? b.order[indexB] : 999;

      return orderA - orderB;
    });

  sortedProducts.forEach((product, i) => {
    renderSingleProductCard(product, i + 1, renderedBrands, parentElement);
  });
}





async function replaceProduct(originalProductCode, alternativeCode, stage) {
  showLoadingOverlay("Замена продукта");
  stage = parseInt(stage, 10);
  const container = document.querySelector(
    `[data-product-code="${originalProductCode}"]`
  );

  const counter = new Counter(container);

  try {
    const response = await postData(
      `${BASE_URL}/core/test_result/?test_type=1`,
      {
        filter_type: "replace",
        data: {
          stage: stage,
          product_code: alternativeCode,
        },
      }
    );

    if (response.result) {
      await counter.deleteProdFromBasket(originalProductCode);
      location.reload();
    } else {
    }
  } catch (error) {
  } finally {
    hideLoadingOverlay();
  }
}

document.addEventListener("click", function (e) {
  const alterButton = e.target.closest(".card__alter");
  if (alterButton) {
    if (alterButton.dataset.listenerAttached !== "true") {
      originalProductCode = alterButton
        .closest("[data-product-code]")
        .getAttribute("data-product-code");
      stage = alterButton.closest("[data-stage]").getAttribute("data-stage");
      alterButton.dataset.listenerAttached = "true";
    }
  }

  const changeButton = e.target.closest(".card__change");
  if (changeButton) {
    if (changeButton.dataset.listenerAttached !== "true") {
      const alternativeProductCode = changeButton
        .closest("[data-product-code]")
        .getAttribute("data-product-code");
      replaceProduct(originalProductCode, alternativeProductCode, stage);

      if (modalStack.current) {
        modalStack.current.close();
      }

      changeButton.dataset.listenerAttached = "true";
    }
  }
});

async function renderRecsData() {
  showLoadingOverlay("Получаем данные по рекомендациям");

  try {
    const { products, beforeFace, afterFace, brandsList, stepsToStages } =
      await getRecsData();

    // Фотографии
    getUserPhoto(beforeFace, afterFace);

    // Отрисовываем карточки товара и собираем бренды
    const renderedBrands = renderProductCards(products, stepsToStages);

    // Фильтруем бренды
    const filteredBrandsList = brandsList.filter((brand) =>
      renderedBrands.has(brand)
    );
    getBrandsList(filteredBrandsList);
  } catch (error) {
    console.error("Ошибка при рендеринге данных:", error);
  } finally {
    hideLoadingOverlay();
  }
}

export { renderRecsData };

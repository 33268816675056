import IMask from "imask";
import { startCountdown } from "./initCountdownCode";
import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { BASE_URL, postData } from "../api";
import { redirectToPageWithParams } from "../../utils/redirectToPage";

const loginText = document.querySelector("[data-login-text]");
const loginPhoneField = document.querySelector("[data-login-phone]");
const loginPhoneBtn = document.querySelector("[data-login-phone-btn]");
const loginCodeField = document.querySelector("[data-login-code]");
const loginCodeBtn = document.querySelector("[data-login-code-btn]");
const loginPhoneInput = document.getElementById("tel");
const loginCodeInput = document.getElementById("code");
const consentCheckbox = document.getElementById("agree");
const phoneError = document.querySelector("[data-login-phone-error]");
const consentError = document.querySelector("[data-login-agree-error]");
const requestCodeButton = document.querySelector("[data-login-repeat-code]");
const logoutBtn = document.querySelector("[data-header-logout]");
const headerMenuAuth = document.querySelectorAll(".header__auth");
const headerLoginBtn = document.querySelector("[data-header-auth-btn]");
const userPhone = document.querySelector("[data-header-phone]");

const COUNTDOWN_DURATION = 30;

function initPhoneMask(input) {
  if (input) {
    const maskOptions = { mask: "+{7}(000)000-00-00" };
    return IMask(input, maskOptions);
  }
}

function validatePhoneInput(input) {
  if (input) {
    const phonePattern = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;
    return phonePattern.test(input.value);
  }
}

function validateConsentCheckbox(checkbox) {
  if (checkbox) {
    return checkbox.checked;
  }
}

function checkValid() {
  let isValid = true;

  if (!validatePhoneInput(loginPhoneInput)) {
    phoneError.style.display = "block";
    isValid = false;
  } else {
    phoneError.style.display = "none";
  }

  if (!validateConsentCheckbox(consentCheckbox)) {
    consentError.style.display = "block";
    isValid = false;
  } else {
    consentError.style.display = "none";
  }

  return isValid;
}

async function sendPhoneNumber() {
  showLoadingOverlay("Готовимся позвонить Вам");
  const phoneInputValue = loginPhoneInput.value;
  try {
    const response = await postData(`${BASE_URL}/users/user_send_phone/`, {
      phone_number: phoneInputValue,
    });

    localStorage.setItem("userPhone", phoneInputValue);
  } catch (error) {
    console.error(error);
  } finally {
    hideLoadingOverlay()
  }
}

async function handlePhoneNumberSubmission() {
  const isValid = checkValid();
  if (!isValid) return;

  await sendPhoneNumber();
  changePageContent();
  startCountdown(COUNTDOWN_DURATION);
}

function changePageContent() {
  loginText.style.display = "none";
  loginPhoneField.style.display = "none";
  loginCodeField.style.display = "flex";
}

async function sendCode() {
  showLoadingOverlay("Верификация");
  const codeInputValue = loginCodeInput.value;
  try {
    const response = await postData(`${BASE_URL}/users/reg_or_login/`, {
      code: codeInputValue,
    });

    return response;
  } catch (error) {
    console.error("Ошибка при отправке кода:", error);
    return false;
  } finally {
    hideLoadingOverlay();
  }
}

let loginInitialized = false;

function initLogin() {
  if (loginInitialized) return;
  loginInitialized = true;

  setTimeout(() => initPhoneMask(loginPhoneInput), 200);

  if (loginPhoneBtn) {
    loginPhoneBtn.addEventListener("click", function (event) {
      event.preventDefault();
      handlePhoneNumberSubmission();
    });
  }

  if (requestCodeButton) {
    requestCodeButton.addEventListener("click", function (event) {
      event.preventDefault();
      if (!requestCodeButton.disabled) {
        sendPhoneNumber();
        startCountdown(COUNTDOWN_DURATION);
      }
    });
  }

  if (loginCodeBtn) {
    loginCodeBtn.addEventListener("click", async function (event) {
      event.preventDefault();
      const response = await sendCode();

  
      if (response && response.result) {
        localStorage.setItem("access", response.data.access);
        localStorage.setItem("refresh", response.data.refresh);
        headerMenuToggle();
        redirectToPageWithParams("index.html");
      } else {
        console.error("Ошибка: response.result пустой или undefined");
        redirectToPageWithParams("error.html");
      }
    });
  }

  headerMenuToggle();
}


function headerMenuToggle() {
  const accessToken = localStorage.getItem("access");
  const authButton = document.querySelector("[data-header-auth-btn]");
  const loginButton = document.querySelector("[data-header-login]");

  if (accessToken) {
    headerMenuAuth.forEach((el) => el.classList.remove("is-closed"));
    if (authButton) {
      authButton.textContent = "Выйти";
      authButton.href = "#";
      authButton.classList.remove("hidden");
      authButton.addEventListener("click", function (event) {
        event.preventDefault();
        initLogout();
      });
    }
    if (loginButton) {
      loginButton.classList.add("hidden");
    }
  } else {
    headerMenuAuth.forEach((el) => el.classList.add("is-closed"));
    if (authButton) {
      authButton.textContent = "Войти";
      authButton.href = "login.html";
      authButton.classList.remove("hidden");
    }
    if (loginButton) {
      loginButton.classList.remove("hidden");
    }
  }
}





function initLogout() {
  const accessToken = localStorage.getItem("access");
  const refreshToken = localStorage.getItem("refresh");
  const authButton = document.querySelector("[data-header-auth-btn]");

  if (accessToken && refreshToken) {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    headerMenuToggle();

    if (authButton) {
      authButton.textContent = "Войти";
      authButton.href = "login.html";
    }
  }
}


export { initLogin, initLogout };

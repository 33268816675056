import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { BASE_URL, postData } from "../api";

const sendBrandsBtn = document.querySelector('[data-filters-accept]');
const resetFiltersBtn = document.querySelector('[data-filters-reset]');
const skinFiltersContainer = document.querySelector('[data-filters-skin]');

const testSkipped = localStorage.getItem('testSkipped') === 'true';

let selectedBrands = JSON.parse(localStorage.getItem('selectedBrands')) || [];

function saveSelectedRadioValue(groupName) {
  const selected = document.querySelector(`input[name="${groupName}"]:checked`);
  if (selected) {
    localStorage.setItem(groupName, selected.value);
  }
}

function restoreSelectedRadioValue(groupName) {
  const savedValue = localStorage.getItem(groupName);
  if (savedValue) {
    const radioToSelect = document.querySelector(`input[name="${groupName}"][value="${savedValue}"]`);
    if (radioToSelect) {
      radioToSelect.checked = true;
    }
  }
}

function getSelectedRadioValue(groupName) {
  const selected = document.querySelector(`input[name="${groupName}"]:checked`);
  return selected ? selected.nextElementSibling.nextElementSibling.textContent : null;
}

restoreSelectedRadioValue('skin_type');
restoreSelectedRadioValue('skin_state');
restoreSelectedRadioValue('allergy');

if(testSkipped && skinFiltersContainer) {
  skinFiltersContainer.style.display = 'flex';
} else if(skinFiltersContainer){
  skinFiltersContainer.style.display = 'none';
}

function getBrandsList(brands) {
  const brandsListCont = document.querySelector("[data-filters-brands]");

  if (!brandsListCont || !brands) {
    return;
  }

  brands.sort((a, b) => {
    const isARussian = /^[А-Яа-яЁё]/.test(a);
    const isBRussian = /^[А-Яа-яЁё]/.test(b);

    if (isARussian && !isBRussian) {
      return -1;
    } else if (!isARussian && isBRussian) {
      return 1;
    } else {
      return a.localeCompare(b, isARussian ? 'ru' : 'en');
    }
  });

  brands.forEach((brand) => {
    const label = document.createElement("li");
    label.innerHTML = `
      <label class="modal__filters-prod">
        <input type="checkbox" name="selected_brand" value="${brand}">
        <span class="modal__filters-check"></span>
        <span class="modal__filters-name">${brand}</span>
      </label>
    `;
    brandsListCont.append(label);
  });

  selectedBrands.forEach((brand) => {
    const checkbox = brandsListCont.querySelector(`input[value="${brand}"]`);
    if (checkbox) {
      checkbox.checked = true;
    }
  });

  const searchInput = document.querySelector("[data-filters-search]");
  if (searchInput) {
    searchInput.addEventListener("input", function () {
      const filter = searchInput.value.toLowerCase();
      const brands = brandsListCont.querySelectorAll("li");

      brands.forEach((brand) => {
        const brandName = brand.textContent.toLowerCase();
        if (brandName.includes(filter)) {
          brand.style.display = "";
        } else {
          brand.style.display = "none";
        }
      });
    });
  }

  if (brandsListCont) {
    brandsListCont.addEventListener("change", function (event) {
      const checkbox = event.target;
      if (checkbox.name === "selected_brand") {
        const brandName = checkbox.value;

        if (checkbox.checked) {
          selectedBrands.push(brandName);
        } else {
          selectedBrands = selectedBrands.filter((name) => name !== brandName);
        }

        localStorage.setItem('selectedBrands', JSON.stringify(selectedBrands));
      }
    });
  }

  if (sendBrandsBtn) {
    sendBrandsBtn.addEventListener('click', () => {
      saveSelectedRadioValue('skin_type');
      saveSelectedRadioValue('skin_state');
      saveSelectedRadioValue('allergy');
      sendBrands();
    });
  }

  if (resetFiltersBtn) {
    resetFiltersBtn.addEventListener('click', () => {
      resetFilters();
      localStorage.removeItem('skin_type');
      localStorage.removeItem('skin_state');
      localStorage.removeItem('allergy');
      localStorage.removeItem('selectedBrands');
      sendBrands();
    });
  }
}

function resetFilters() {
  const brandsListCont = document.querySelector("[data-filters-brands]");
  const checkboxes = brandsListCont.querySelectorAll('input[type="checkbox"]');
  const searchInput = document.querySelector(".brand-search");

  checkboxes.forEach(checkbox => {
    checkbox.checked = false;
  });

  selectedBrands = [];
  localStorage.setItem('selectedBrands', JSON.stringify(selectedBrands));

  if (searchInput) {
    searchInput.value = '';
  }

  const brands = brandsListCont.querySelectorAll("li");
  brands.forEach(brand => {
    brand.style.display = '';
  });

  const radioGroups = ['skin_type', 'skin_state', 'allergy'];
  radioGroups.forEach(groupName => {
    const radios = document.querySelectorAll(`input[name="${groupName}"]`);
    radios.forEach(radio => {
      radio.checked = false;
    });
  });
}

async function sendBrands() {
  showLoadingOverlay('Фильтруем бренды');

  const skinType = getSelectedRadioValue('skin_type');
  const skinState = getSelectedRadioValue('skin_state');
  const allergy = getSelectedRadioValue('allergy');

  try {
    const response = await postData(`${BASE_URL}/core/test_result/?test_type=1`,
      {
        "filter_type": ["brand"],
        "data": {
          "skin_type": skinType,
          "skin_state": skinState,
          "allergy": allergy,
          "brands": selectedBrands
        }
      }
    );
    if (response.result) {
      location.reload();
    }
  } catch (error) {
    console.error(error);
  } finally {
    hideLoadingOverlay();
  }
}

export { getBrandsList, selectedBrands };

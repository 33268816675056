import {
  hideLoadingOverlay,
  showLoadingOverlay,
} from "../../utils/loadingScreen";
import { redirectToPageWithParams } from "../../utils/redirectToPage";
import { moveToNextStage } from "../accessControl";
import { BASE_URL, getData, postData } from "../api";
import { getRecsData } from "../recs/getRecsData";

function handleInitialQuestion() {
  document.addEventListener("click", async function (event) {
    const warningModalTest = document.getElementById("warningModalTest");

    if (warningModalTest && warningModalTest.contains(event.target)) {
      if (event.target.matches("[data-warning-btn2]")) {
        await markTestAsSkipped();
        await sendTestData(event, "skip", true);
      } else if (event.target.matches("[data-warning-btn1]")) {
        hideModal("warningModalTest");
      }
    }
  });
}

class QuestionCard {
  static answers = JSON.parse(localStorage.getItem("test_answers")) || [];
  static history = [];

  constructor(question, container, initialQuestionId, lastQuestionId) {
    this.question = question;
    this.container = container;
    this.cardElement = document.createElement("div");
    this.initialQuestionId = initialQuestionId;
    this.lastQuestionId = lastQuestionId;
    this.initCard();
  }

  initCard() {
    this.cardElement.className = "test__question";
    this.cardElement.setAttribute(
      "data-question-id",
      `${this.question.question_id}`
    );

    if (this.question.question_id === this.initialQuestionId) {
      this.cardElement.classList.add("is-active");
    }

    this.addQuestionTitle();

    if (this.question.question_id === 6) {
      this.addTooltip();
    }

    this.addAnswers();

    if (this.question.question_id > 1) {
      this.addBackButton();
    }

    this.container.appendChild(this.cardElement);


    if (this.question.question_id === this.lastQuestionId) {
      this.addFinalSubmitButton(this.cardElement);
    }
  }

  addBackButton() {
    const backButton = document.createElement("button");
    backButton.classList.add("test__button-back", "btn");
    backButton.textContent = "К предыдущему вопросу";

    backButton.addEventListener("click", () => {
      this.goBack();
    });

    this.cardElement.appendChild(backButton);
  }

  goBack() {
    if (QuestionCard.history.length > 0) {
        const lastQuestionId = QuestionCard.history.pop();
        const currentQuestion = document.querySelector(".test__question.is-active");

        if (currentQuestion) {
            currentQuestion.classList.remove("is-active");
        }

        const previousQuestion = document.querySelector(
            `.test__question[data-question-id="${lastQuestionId}"]`
        );
        
        if (previousQuestion) {
            previousQuestion.classList.add("is-active");
        } else {
            console.error("Предыдущий вопрос не найден.");
        }

        QuestionCard.updateProgressBar();
    }
}


  addQuestionTitle() {
    const questionTitle = document.createElement("h2");
    questionTitle.textContent = this.question.text;
    this.cardElement.appendChild(questionTitle);
  }

  addAnswers() {
    const answersList = document.createElement("ul");
    if (this.question.type === "number") {
      const answerItem = document.createElement("li");
      this.createInput(answerItem);
      answersList.appendChild(answerItem);
    } else {
      this.question.answers.forEach((answer) => {
        const answerItem = document.createElement("li");
        this.createButton(answer, answerItem);
        answersList.appendChild(answerItem);
      });
    }
    this.cardElement.appendChild(answersList);
  }

  createButton(answer, parentElement) {
    const answerContainer = document.createElement("div");
    answerContainer.className = "answer-container";

    const answerButton = document.createElement("button");
    answerButton.className = "test__button";
    answerButton.textContent = answer.text;

    const selectedAnswer = QuestionCard.answers.find(
      (ans) => ans.question_id === this.question.question_id
    );
    if (selectedAnswer && selectedAnswer.answer_id === answer.answer_id) {
      answerButton.classList.add("is-chosen");
    }

    answerButton.addEventListener("click", () =>
      this.selectAnswer(answer.answer_id, answer.next_question, answerButton)
    );

    const tooltipIcon = document.createElement("button");
    tooltipIcon.className = "tooltip__icon";

    const tooltipText = document.createElement("span");
    tooltipText.className = "tooltip-text";
    tooltipText.textContent = answer.comment;

    tooltipText.style.visibility = "hidden";
    tooltipText.style.opacity = "0";

    tooltipIcon.appendChild(tooltipText);

    tooltipIcon.addEventListener("mouseenter", function () {
      tooltipText.style.visibility = "visible";
      tooltipText.style.opacity = "1";
    });

    tooltipIcon.addEventListener("mouseleave", function () {
      tooltipText.style.visibility = "hidden";
      tooltipText.style.opacity = "0";
    });

    tooltipIcon.addEventListener("click", function (event) {
      event.stopPropagation();

      document.querySelectorAll(".tooltip-text").forEach(function (tooltip) {
        tooltip.style.visibility = "hidden";
        tooltip.style.opacity = "0";
      });

      tooltipText.style.visibility = "visible";
      tooltipText.style.opacity = "1";
    });

    document.addEventListener("click", function () {
      document.querySelectorAll(".tooltip-text").forEach(function (tooltip) {
        tooltip.style.visibility = "hidden";
        tooltip.style.opacity = "0";
      });
    });

    answerContainer.appendChild(answerButton);
    answerContainer.appendChild(tooltipIcon);

    parentElement.appendChild(answerContainer);
  }

  createInput(parentElement) {
    const ageInput = document.createElement("input");
    ageInput.classList.add("test__age");
    ageInput.type = "number";
    ageInput.id = "age";
    ageInput.max = "100";
    ageInput.min = "1";
    ageInput.step = "1";
    ageInput.name = `question_${this.question.question_id}`;
    ageInput.value = this.question.answer_id || "";
    ageInput.placeholder = this.question.text;
    ageInput.required = true;

    ageInput.addEventListener("input", () => {
      this.selectAnswer(parseInt(ageInput.value, 10), null);
      if (!/^\d*$/.test(ageInput.value)) {
        ageInput.value = ageInput.value.replace(/[^\d]/g, "");
      }

      const value = parseInt(ageInput.value, 10);
      if (value < 1 || value > 100 || isNaN(value)) {
        ageInput.value = "";
      }
    });

    const label = document.createElement("label");
    label.htmlFor = "age";
    parentElement.appendChild(ageInput);
    parentElement.appendChild(label);
    label.className = "visually-hidden";
  }

  updateNextButtonState() {
    const checkboxes = this.cardElement.querySelectorAll(
      'input[type="checkbox"]'
    );
    const anyChecked = Array.from(checkboxes).some(
      (checkbox) => checkbox.checked
    );
    const nextButton = this.cardElement.querySelector(".test__button-next");
    if (nextButton) {
      nextButton.disabled = !anyChecked;
    }
  }

  addTooltip() {
    const tooltip = document.createElement("div");
    tooltip.textContent =
      "Чувствительная кожа склонна к повышенной раздражительности и чрезмерной реакции в ответ на различные факторы (климат, косметику, ингредиенты и т. д.). Важно учесть возможность индивидуальной непереносимости.";
    tooltip.className = "test__tooltip";
    this.cardElement.appendChild(tooltip);
  }

  selectAnswer(answerId, nextQuestionId, buttonElement = null) {
    if (nextQuestionId !== null) {
      const currentQuestion = document.querySelector(
        ".test__question.is-active"
      );
      if (currentQuestion) {
        currentQuestion.classList.remove("is-active");
        QuestionCard.history.push(this.question.question_id);
      }
    }

    QuestionCard.updateAnswer(this.question.question_id, answerId);

    if (buttonElement) {
      const buttons = this.cardElement.querySelectorAll("button");
      buttons.forEach((btn) => btn.classList.remove("is-chosen"));
      buttonElement.classList.add("is-chosen");
    }

    if (nextQuestionId !== null) {
      const nextQuestion = document.querySelector(
        `.test__question[data-question-id="${nextQuestionId}"]`
      );
      if (nextQuestion) {
        nextQuestion.classList.add("is-active");
      } else {
        console.error("Следующий вопрос не найден.");
      }
    }

    QuestionCard.updateProgressBar();
  }

  navigateQuestion(questionId) {
    const currentQuestion = document.querySelector(".test__question.is-active");
    if (currentQuestion) {
      currentQuestion.classList.remove("is-active");
    }

    const targetQuestion = document.querySelector(
      `.test__question[data-question-id="${questionId}"]`
    );
    if (targetQuestion) {
      targetQuestion.classList.add("is-active");
    } else {
      console.error("Вопрос не найден.");
    }
  }

  static updateAnswer(questionId, answerId) {
    const index = QuestionCard.answers.findIndex(
      (ans) => ans.question_id === questionId
    );
    if (index !== -1) {
      QuestionCard.answers[index].answer_id = answerId;
    } else {
      QuestionCard.answers.push({
        question_id: questionId,
        answer_id: answerId,
      });
    }
    localStorage.setItem("test_answers", JSON.stringify(QuestionCard.answers));
  }

  static updateProgressBar() {
    const steps = document.querySelectorAll(".test__progress-step");
    steps.forEach((step, index) => {
      step.classList.toggle("active", index === QuestionCard.history.length);
    });
  }

  addFinalSubmitButton(parentElement) {
    const submitButton = document.createElement("button");
    submitButton.classList.add("test__send");
    submitButton.textContent = "Отправить";
    submitButton.classList.add("test__button-submit", "btn", "btn--black");
    submitButton.addEventListener("click", (event) => {
      event.preventDefault();
      markTestAsDone();
      sendTestData(event, QuestionCard.answers, true);
    });
    parentElement.appendChild(submitButton);
  }
}

function createProgressBar(totalSteps) {
  const progressBar = document.querySelector(".test__progress-bar");
  progressBar.innerHTML = "";

  for (let i = 0; i < totalSteps; i++) {
    const step = document.createElement("li");
    step.classList.add("test__progress-step");
    step.dataset.step = i;
    progressBar.appendChild(step);
  }

  QuestionCard.updateProgressBar();
}

async function getTestData() {
  QuestionCard.history = [];
  QuestionCard.answers = [];
  showLoadingOverlay("Получаем список вопросов");

  try {
    const response = await getData(`${BASE_URL}/core/test/?test_type=1`);
    const questions = response.data.questions;
    let startQuestionId = response.data.start_question;
    const container = document.querySelector(".test__data");

    if (!container) return;
    container.innerHTML = "";

    const lastQuestion = questions.find(q => 
      !q.answers || q.answers.every(a => !a.next_question)
    );
    const lastQuestionId = lastQuestion ? lastQuestion.question_id : null;

    let initialQuestion = null;

    questions.forEach((question) => {
      const questionCard = new QuestionCard(question, container, startQuestionId, lastQuestionId);
      if (question.question_id === startQuestionId) {
        initialQuestion = questionCard;
      }
    });

    createProgressBar(questions.length - 1);

    if (initialQuestion) {
      initialQuestion.cardElement.classList.add("is-active");
    } else {
      console.error("Начальный вопрос не найден в полученных данных.");
    }
  } catch (error) {
    console.error("Ошибка при загрузке вопросов:", error);
  } finally {
    hideLoadingOverlay();
  }
}


async function markTestAsDone() {
  localStorage.setItem("testSkipped", "false");
}

async function markTestAsSkipped() {
  localStorage.setItem("testSkipped", "true");
}

async function sendTestData(event, data, redirect) {
  event.preventDefault();
  showLoadingOverlay("Сохраняем ответы");
  try {
    await postData(`${BASE_URL}/core/test/?test_type=1`, {
      data: data,
    });
    moveToNextStage();
    localStorage.removeItem("test_answers");
    redirectToPageWithParams("results.html", "face");
    getRecsData();
  } catch (error) {
    console.error("Ошибка при отправке ответов:", error);
    console.error(error.config.data);
  } finally {
    hideLoadingOverlay();
  }
}

export { getTestData, handleInitialQuestion };

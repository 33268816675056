import { BASE_URL, getData, postData } from "../api";
import { redirectToPageWithParams } from "../../utils/redirectToPage";
import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { moveToNextStage } from "../accessControl";


// города
function fillCitySelect(cities, shops) {
  const citySelect = document.querySelector('select[data-city]');
  if (citySelect) {
    citySelect.innerHTML = '';
    citySelect.addEventListener('change', () => filterAddresses(citySelect.value, shops));

    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.textContent = 'Выберите город';
    defaultOption.disabled = true;
    defaultOption.selected = true;
    citySelect.appendChild(defaultOption);

    const sortedCities = cities.sort((a, b) => a.localeCompare(b));

    sortedCities.forEach(city => {
      const option = document.createElement('option');
      option.value = city;
      option.textContent = city;
      citySelect.appendChild(option);
    });
  }
}

// адреса
function fillAddressSelect(shops, selectedCity = "") {
  const addressSelect = document.querySelector('select[data-address]');
  if (addressSelect) {
    addressSelect.innerHTML = '';

    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.textContent = 'Выберите адрес';
    defaultOption.disabled = true;
    defaultOption.selected = true;
    addressSelect.appendChild(defaultOption);

    const sortedShops = shops.sort((a, b) => {
      const addressA = a.address.toLowerCase();
      const addressB = b.address.toLowerCase();
      return addressA.localeCompare(addressB);
    });

    sortedShops.forEach(shop => {
      const option = document.createElement('option');
      option.value = shop.id;
      let address = shop.address;

      if (selectedCity && address.startsWith(selectedCity)) {
        address = address.slice(selectedCity.length + 2);
      }

      option.textContent = address;
      addressSelect.appendChild(option);
    });

    addressSelect.onchange = () => {
      const selectedId = addressSelect.value;
      const selectedShop = shops.find(shop => shop.id.toString() === selectedId);
      
      if (selectedShop) {
        const coords = selectedShop.point.split(',').map(Number);
        
        myMap.setCenter(coords, 15);
      }
    };
  }
}


async function getUserLocation() {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          });
        },
        (error) => {
          console.warn("Геолокация не доступна:", error);
          resolve(null);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.warn("Геолокация не поддерживается");
      resolve(null);
    }
  });
}


function filterAddresses(selectedCity, shops) {
  let filteredShops = shops.filter(shop => shop.city === selectedCity);
  fillAddressSelect(filteredShops, selectedCity);

  ymaps.geocode(selectedCity, { results: 1 }).then(function (res) {
    const firstGeoObject = res.geoObjects.get(0);
    const coords = firstGeoObject.geometry.getCoordinates();
    myMap.setCenter(coords, 10);
  });
}

// получаем от сервака
async function getShopsList() {
  showLoadingOverlay("Определяем ваше местоположение...");

  try {
    const userLocation = await getUserLocation();
    const response = await getData(`${BASE_URL}/shop/shop_list/?test_type=1`);
    const shops = response.data.shops;
    const cities = [...new Set(shops.map(shop => shop.city))];

    let nearestCity = null;
    let nearestShop = null;
    let nearestShops = shops;

    if (userLocation) {
      let minDistance = Infinity;
      shops.forEach(shop => {
        const shopCoords = shop.point.split(',').map(Number);
        const distance = Math.hypot(
          shopCoords[0] - userLocation.lat,
          shopCoords[1] - userLocation.lon
        );
        if (distance < minDistance) {
          minDistance = distance;
          nearestCity = shop.city;
          nearestShop = shop;
        }
      });

      nearestShops = shops.filter(shop => shop.city === nearestCity);
    }

    fillCitySelect(cities, shops);
    fillAddressSelect(nearestShops, nearestCity);

    const citySelect = document.querySelector('select[data-city]');
    const addressSelect = document.querySelector('select[data-address]');

    if (citySelect && nearestCity) {
      citySelect.value = nearestCity;
    }
    if (addressSelect && nearestShop) {
      addressSelect.value = nearestShop.id;
    }

    return { uniqueCities: cities, address: shops, nearestShop };
  } catch (error) {
    console.error("Ошибка загрузки магазинов:", error);
  } finally {
    hideLoadingOverlay();
  }
}


// иниц карту
var myMap;
async function initShopsMap() {
  if (document.getElementById('map')) {
    ymaps.ready(async function () {
      try {
        const result = await getShopsList();
        const shops = result.address;
        const nearestShop = result.nearestShop;
        let nearestPlacemark = null;

        let mapCenter = [55.76, 37.64];

        if (nearestShop) {
          const shopCoords = nearestShop.point.split(',').map(Number);
          mapCenter = shopCoords;
        }

        myMap = new ymaps.Map("map", {
          center: mapCenter,
          zoom: 12, 
          controls: ['zoomControl']
        });

        shops.forEach(shop => {
          const coords = shop.point.split(',').map(Number);

          const balloonContentLayout = ymaps.templateLayoutFactory.createClass(
            `<div class="map__baloon">
              <div class="map__baloon-title">Магнит Косметик</div>
              <div class="map__baloon-addr">${shop.city}, ${shop.address}</div>
              <div class="map__baloon-text"></div>
              <div class="map__baloon-subtext">${shop.description}</div>
              <button type="button" class="map__baloon__button btn btn--pink" data-shop-id="${shop.id}">Выбрать магазин</button>
            </div>`, {
            build: function () {
              this.constructor.superclass.build.call(this);
              const btn = this.getElement().querySelector('.map__baloon__button');
              btn.addEventListener('click', async () => {
                const shopId = btn.getAttribute('data-shop-id');
                try {
                  showLoadingOverlay("Сохраняем выбранный магазин");

                  await postData(`${BASE_URL}/shop/shop/?test_type=1`, {
                    'shop_id': shopId
                  });

                  moveToNextStage();
                  const initiatedByChangeShop = sessionStorage.getItem('initiatedByChangeShop');
                  if (initiatedByChangeShop) {
                    sessionStorage.removeItem('initiatedByChangeShop');
                    redirectToPageWithParams('recs.html', 'test_type');
                  } else {
                    redirectToPageWithParams('recs.html', 'test_type');
                  }
                } catch (error) {
                  console.error('Ошибка отправки данных:', error);
                } finally {
                  hideLoadingOverlay();
                }
              });
            },
          });

          const myPlacemark = new ymaps.Placemark(coords, {
            hintContent: shop.name,
            balloonContentHeader: shop.name,
            balloonContentBody: shop.address
          }, {
            balloonContentLayout: balloonContentLayout, 
            preset: 'islands#pinkIcon',
            iconCaptionMaxWidth: "50",
            balloonMaxWidth: 250,
          });

          myMap.geoObjects.add(myPlacemark);

          if (nearestShop && nearestShop.id === shop.id) {
            nearestPlacemark = myPlacemark;
          }
        });

        if (nearestPlacemark) {
          myMap.setCenter(nearestPlacemark.geometry.getCoordinates(), 15);
        }

      } catch (error) {
        console.error("Ошибка при инициализации карты:", error);
      }
    });
  }
}

// отправка id магазина
function postShopId() {
  const nextButtons = document.querySelectorAll('.map__next[data-next]');
  if (nextButtons) {
    nextButtons.forEach(btn => btn.addEventListener('click', async function (event) {

      event.preventDefault();
      const addressSelect = document.querySelector('select[data-address]');
      const selectedShopId = addressSelect.value;

      if (selectedShopId) {
        showLoadingOverlay("Сохраняем выбранный магазин");
        try {
          moveToNextStage();
          await postData(`${BASE_URL}/shop/shop/?test_type=1`, {
            'shop_id': selectedShopId,
          });
          redirectToPageWithParams('recs.html', 'face');
        } catch (error) {
        } finally {
          hideLoadingOverlay();
        }
      } else {
        alert('Пожалуйста, выберите магазин.');
      }
    }));
  }
}

const changeShopBtn = document.querySelector('[data-change-shop]')
if (changeShopBtn) {
  changeShopBtn.addEventListener('click', () => {
    sessionStorage.setItem('initiatedByChangeShop', 'true');
    window.location.href = 'map.html';
  });
}


export { getShopsList, initShopsMap, postShopId }
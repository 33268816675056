import {
  hideLoadingOverlay,
  showLoadingOverlay,
} from "../../utils/loadingScreen";
import { moveToNextStage } from "../accessControl";
import { BASE_URL, getData } from "../api";

const faceSkinIndex = document.querySelector("[data-result-index]");
const faceSkinType = document.querySelector("[data-result-type]");
const faceSkinCondition = document.querySelector("[data-result-cond]");
const faceSkinDiseasesList = document.querySelector("[data-result-diseases]");
const resultPhotoContainer = document.querySelector("[data-result-photo]");
const faceSubType = document.querySelector("[data-result-sub]");
const faceComps = document.querySelector("[data-result-components]");
const resultPoint = document.querySelector("[data-result-point]");

async function getResults(type, testId) {
  showLoadingOverlay("Получаем результаты исследования");
  let url;
  if (type === "usual") {
    url = `${BASE_URL}/core/test_result_neuro/?test_type=1`;
  } else if (type === "profile") {
    url = `${BASE_URL}/users/test?tp_id=${testId}`;
  }

  try {
    const response = await getData(url);

    moveToNextStage();
    const indexString = response.data;

    const resultCond = indexString.test_result.skin_state;

    const resultType = indexString.test_result.skin_type;
    const resultSubState = indexString.test_result.sub_skin_states
      ? Object.entries(indexString.test_result.sub_skin_states)[0]
      : ["Купероз", "N/A"];
    const [firstCondition, firstSeverity] = resultSubState;

    const resultIndex = indexString.test_result.face_rank;
    const resultPhoto = indexString.photo_url;
    const resultDiseases = indexString.features;
    const resultSkinPoint = indexString.test_result.norm_range.name;

    if (resultPhotoContainer) {
      let imgElement = resultPhotoContainer.querySelector("img");

      if (!imgElement) {
        imgElement = document.createElement("img");
        imgElement.alt = "Ваша фотография.";
        imgElement.width = 200;
        imgElement.height = 200;
        resultPhotoContainer.appendChild(imgElement);
      }

      if (!imgElement.src) {
        imgElement.src = "../images/face-ex@2x.png";
      }

      if (resultPhoto) {
        imgElement.src = resultPhoto;
      }
    }

    if (faceComps) {
      const faceComponents =
        indexString.inci_components && indexString.inci_components.length > 0
          ? indexString.inci_components
          : ["Флавоноиды"];

      faceComponents.forEach((item) => {
        const compName = item.name || "Флавоноиды";
        const compItem = document.createElement("li");
        compItem.textContent = compName;
        faceComps.appendChild(compItem);
      });
    }

    resultPoint.textContent = resultSkinPoint;

    faceSkinIndex.innerHTML = `${resultIndex.toFixed(1)} <b>/ 10</b>`;
    if (resultIndex >= 7) {
      faceSkinIndex.classList.add("good");
    } else if (resultIndex < 7 && resultIndex >= 5) {
      faceSkinIndex.classList.add("normal");
    } else {
      faceSkinIndex.classList.add("bad");
    }
    faceSkinType.textContent = resultType || "Нет данных";
    faceSkinCondition.textContent = resultCond || "Нет данных";
    faceSubType.textContent = firstCondition;

    resultDiseases.forEach((item) => {
      const listItem = document.createElement("li");
      const listItemText = document.createElement("span");
      const listItemLvl = document.createElement("div");
      const listItemBar = document.createElement("div");

      listItemLvl.classList.add("result__line");
      listItemBar.classList.add("result__bar");

      listItemText.textContent = item.name;
      listItemBar.style.width = `${item.value * 10}%`;

      faceSkinDiseasesList.appendChild(listItem);
      listItem.appendChild(listItemText);
      listItem.appendChild(listItemLvl);
      listItemLvl.appendChild(listItemBar);
    });

    document.querySelectorAll("result__next[data-next]").forEach((button) => {
      button.href = updateQueryParam(button.href, "face", currentTestType);
    });
    updateSkinIndexBars(resultIndex);
  } catch (error) {
  } finally {
    hideLoadingOverlay();
  }
}

function updateSkinIndexBars(resultIndex) {
  const barsContainer = document.querySelector("[data-result-bars]");

  if (!barsContainer) return;

  barsContainer.innerHTML = "";

  const filledCount = Math.round(resultIndex);

  for (let i = 0; i < 10; i++) {
    const bar = document.createElement("div");
    bar.classList.add("result__stick");
    bar.setAttribute("data-index", i);

    if (i < filledCount) {
      bar.classList.add("filled");

      if (resultIndex >= 7) {
        bar.classList.add("good");
      } else if (resultIndex < 7 && resultIndex >= 5) {
        bar.classList.add("normal");
      } else {
        bar.classList.add("bad");
      }
    }

    barsContainer.appendChild(bar);
  }
}

export { getResults };

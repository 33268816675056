import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { BASE_URL, postData } from "../api";

async function sendTestType() {

  try {
    showLoadingOverlay("Начинаем тест")
    const response = await postData(`${BASE_URL}/core/start_new_test/?test_type=1`);
    if (response) {
      hideLoadingOverlay()
      return response.result
    }
  } catch (error) {
    return false
  }
}

export { sendTestType }
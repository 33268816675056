import { hideLoadingOverlay, showLoadingOverlay } from "../../utils/loadingScreen";
import { BASE_URL, getData } from "../api";

const testsList = document.querySelector('[data-profile-tests]')

class UserTestsCard {
  constructor(type, date, id) {
    this.type = type
    this.date = date
    this.id = id
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return date.toLocaleDateString('ru-RU', options);
  }

  render() {
    const testCard = document.createElement('li');
    testCard.classList.add('profile__test', 'face')

    let icon;
    let title;
    switch (this.type) {
      case 1:
        icon = "test-face";
        title = "Лицо";
        break;
    }

    const formattedDate = this.formatDate(this.date);

    testCard.innerHTML = `
      <div>
        <span>
          <svg width="32" height="32" aria-hidden="true">
            <use xlink:href="#${icon}"></use>
          </svg>
        </span>
        <h2>${title}</h2>
      </div>
      <p>${formattedDate}</p>
      <a class="btn btn--pink" href=profile-result.html id=${this.id} data-profile-link>Результат</a>
    `;

    testsList.append(testCard)
  }
}

async function getUserTestsList() {
  showLoadingOverlay("Загружаем список тестов");

  try {
    const response = await getData(`${BASE_URL}/users/list_of_tests`);

    const userTests = response.data
    return userTests

  } catch {
  } finally {
    hideLoadingOverlay();
  }
}

async function renderUserTestsData() {
  try {
    const testsData = await getUserTestsList()

    if (testsData) {
      testsData.forEach(({test_type, created_at, id}) => {
        new UserTestsCard(test_type, created_at, id).render()
      })
      getUserTestId() 
    }
  } catch (error) {
    console.error(error)
  }
}

function getUserTestId() {
  const links = document.querySelectorAll('[data-profile-link]');
  links.forEach(link => {
    link.addEventListener('click', (event) => {
      const testId = event.target.id;
      localStorage.setItem('testId', testId);
    });
  });
}

export { renderUserTestsData, getUserTestId };

import { BASE_URL, getData } from "../api";

async function getRecsData() {
  try {
    const response = await getData(`${BASE_URL}/core/test_result/?test_type=1`);
    const shopAddress = response.data.shop_address || "Адрес не указан";
    let products = response.data.products_info || [];

    const stepsToStages = response.data.steps_to_stages || {};
    const productsArray = Object.entries(products).map(([key, value]) => ({ key, ...value }));


    const userPhoto = response.data.test_result;

    let testResultObject = {};
    try {
      testResultObject = typeof userPhoto === "string" ? JSON.parse(userPhoto) : userPhoto;
    } catch (error) {
      console.error("Ошибка при парсинге данных test_result:", error);
    }
    
    const beforeFace = testResultObject.before_face || null;
    const afterFace = testResultObject.after_face || null;
    
    const brandsList = response.data.brands || [];

    return { shopAddress, products: productsArray, beforeFace, afterFace, brandsList, stepsToStages }; 
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
    return {}; 
  }
}





export { getRecsData };

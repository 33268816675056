import { hideLoadingOverlay, showLoadingOverlay } from '../../utils/loadingScreen';
import { BASE_URL, postData } from '../api';

const prodTitle = document.querySelector('[data-detail-title]');
const prodPrice = document.querySelector('[data-detail-price]');
const prodImg = document.querySelector('[data-detail-img]');
const prodSkinType = document.querySelector('[data-detail-type]');
const prodBrand = document.querySelector('[data-detail-brand]');
const prodComps = document.getElementById('incs');
const prodEffects = document.getElementById('effects');
const prodApplic = document.getElementById('recs');
const prodDescr = document.getElementById('descr');

async function getProductDetails(productCode) {
  showLoadingOverlay("Загружаются данные о товаре");
  try {
    const response = await postData(`${BASE_URL}/shop/product/?test_type=1`, {
      'product_code': `${productCode}`
    });

    if (!response || !response.data) {
      console.error("Ошибка: данные товара не загружены!");
      return {};
    }

    return response.data;
  } catch (error) {
    console.error("Ошибка при загрузке товара:", error);
    return {};
  } finally {
    hideLoadingOverlay();
  }
}


function createProdDetailList(data, container) {
  if (!Array.isArray(data)) {
    data = [];
  }

  if (container.id === "incs") {
    container.classList.add("result__components");
  }

  if (data.length === 0) {
    const noDataElement = document.createElement('li');
    noDataElement.textContent = 'Нет данных';
    container.appendChild(noDataElement);
  } else {
    data.forEach(item => {
      const li = document.createElement('li');
      li.textContent = typeof item === 'object' && item.name ? item.name : item;
      container.appendChild(li);
    });
  }
}

async function updateProductDetails(details) {
  if (!details) {
    console.error("Ошибка: details не передан!");
    return;
  }

  prodComps.innerHTML = [''];
  prodEffects.innerHTML = '';
  prodApplic.innerHTML = '';
  prodDescr.innerHTML = '';

  prodTitle.textContent = details.name || 'Нет данных';
  prodPrice.textContent = details.price ? details.price + ' ₽' : 'Цена не указана';
  prodImg.src = details.img || 'default-image.png';
  prodSkinType.textContent = details.skin_type?.length > 0 ? details.skin_type.join(', ') : 'Для всех типов кожи';
  prodBrand.textContent = details.brand || 'Нет бренда';

  createProdDetailList(details.components, prodComps);
  createProdDetailList(details.effects, prodEffects);
  createProdDetailList(details.applications, prodApplic);
  createProdDetailList([details.description || 'Описание отсутствует'], prodDescr);
}


function setProductDetails() {
  const prodDetailsBtns = document.querySelectorAll('[data-modal="details"]');
  if (prodDetailsBtns) {
    prodDetailsBtns.forEach(btn => btn.addEventListener('click', async (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const productCard = evt.target.closest('.card__prod')
      if (productCard) {
        const productCode = productCard.getAttribute('data-product-code');
        if (productCode) {

          const details = await getProductDetails(productCode);
          if (details) {
            await updateProductDetails(details);
          }
        }
      }
    }));
  }
}

if (!window.accordionInitialized) {
  window.accordionInitialized = true;

  document.addEventListener('DOMContentLoaded', function () {
    const detailTitles = document.querySelectorAll('.prod__details-title');

    detailTitles.forEach(title => {
      title.addEventListener('click', function (evt) {
        evt.stopPropagation();
        
        const listId = this.getAttribute('data-toggle');
        const detailList = document.getElementById(listId);

        if (detailList) {
          detailList.classList.toggle('hidden');

          const arrow = this.querySelector('.prod__details-arrow');
          if (arrow) {
            arrow.classList.toggle('rotated');
          }
        }
      });
    });
  });
}


export { setProductDetails };

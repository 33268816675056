import { sendTestType } from "../user/sendTestType";

const screens = document.querySelectorAll('.photo__screen');
const buttonsNext = document.querySelectorAll('[data-photo-use]');
const photoLoadBtns = document.querySelectorAll('[data-photo-load]');

let isTestTypeSent = false;

async function showScreen(index) {
  screens.forEach((screen, i) => {
    screen.classList.toggle('visually-hidden', i !== index);
  });

  if (index === 0 && !isTestTypeSent) {
    try {
      const res = await sendTestType();
      if (res) {
        isTestTypeSent = true;
      }
    } catch (e) {
      console.error("❌ Ошибка при отправке sendTestType():", e);
    }
  }
}

if (photoLoadBtns) {
  photoLoadBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      showScreen(1);
    });
  });
}

buttonsNext.forEach(button => {
  button.addEventListener('click', () => {
    const currentIndex = Array.from(screens).findIndex(screen => !screen.classList.contains('visually-hidden'));
    const nextIndex = currentIndex + 1;
    if (nextIndex < screens.length) {
      showScreen(nextIndex);
    }
  });
});

export { showScreen };

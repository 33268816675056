import { hideLoadingOverlay, showLoadingOverlay } from "../utils/loadingScreen";
import { redirectToPageWithParams } from "../utils/redirectToPage";
import { sendTestType } from "./user/sendTestType";

const beginNewTestBtns = document.querySelectorAll('[data-test]');
const sideLinks = document.querySelectorAll('[data-side-link]');
const photoUploaded = localStorage.getItem('photoUploaded') === 'true';
const currentPage = window.location.pathname.split('/').pop();
const testSkipped = localStorage.getItem('testSkipped') === 'true'

const stages = [
  "photo.html",
  "test.html",
  "results.html",
  "map.html",
  "recs.html",
];

const showResultsUnavailableOverlay = (text, text2, btnDescr1) => {
  const overlay = document.createElement('div');
  overlay.id = 'results-overlay';
  overlay.className = 'results__overlay';

  const message = document.createElement('h3');
  message.textContent = `${text}, поэтому мы не смогли создать ваш дерматологический профиль. ${text2}`;
  const message2 = document.createElement('h4')
  message2.textContent = 'Вы можете:'

  const optionsContainer = document.createElement('div');
  optionsContainer.className = 'results__overlay-container';

  const option1 = document.createElement('p');
  const recsButton = document.createElement('button');
  recsButton.textContent = 'К выбору магазина';
  recsButton.className = 'btn btn--pink';
  recsButton.addEventListener('click', () => {
    redirectToPageWithParams('map.html', 'test_type');
  });

  const option1Text = document.createTextNode(`${btnDescr1}`);
  option1.appendChild(option1Text);
  option1.appendChild(recsButton);

  const option2 = document.createElement('p');
  const photoButton = document.createElement('button');
  photoButton.textContent = 'Загрузить фото';
  photoButton.className = 'btn';
  photoButton.addEventListener('click', () => {
    redirectToPageWithParams('photo.html', 'test_type');
  });

  const option2Text = document.createTextNode(' – Позволить нам провести анализ кожи и предложить персонализированные рекомендации, основанные на вашем дерматологическом профиле.');
  option2.appendChild(option2Text);
  option2.appendChild(photoButton);

  optionsContainer.appendChild(option1);
  optionsContainer.appendChild(option2);

  overlay.appendChild(message);
  overlay.appendChild(message2);
  overlay.appendChild(optionsContainer);
  document.body.appendChild(overlay);
};



if (currentPage === 'results.html' && testSkipped && !photoUploaded) {
  showResultsUnavailableOverlay(
    'Этапы загрузки фотографии и анкетирования были пропущены',
    '',
    '– Выбрать параметры ухода самостоятельно, основываясь на ваших предпочтениях.'
  );
} else if (currentPage === 'results.html' && !photoUploaded) {
  showResultsUnavailableOverlay(
    'Фото не было загружено',
    'Однако на основе вашей анкеты мы сформируем рекомендации.',
    ' – Получить уходовые советы на основе информации из анкеты.',
  );
}


if (beginNewTestBtns) {
  beginNewTestBtns.forEach(btn => {
    if (!btn.dataset.listenerAdded) {
      btn.addEventListener('click', async (e) => {
        localStorage.removeItem('testType');
        e.preventDefault()
        const testType = btn.getAttribute('data-type');
        const targetHref = btn.getAttribute('href')
        localStorage.setItem('testType', testType);
        const response = await startNewTest();
        if (response) {
          setCurrentStageIndex(0);
          redirectToPageWithParams(targetHref, 'test_type');
        } else {
          alert('Ошибка в запросе')
        }
      });
      btn.dataset.listenerAdded = 'true';
    }
  });
}

function getCurrentStageIndex() {
  const currentStage = sessionStorage.getItem("currentStage") || stages[0];
  return stages.indexOf(currentStage);
}

function setCurrentStageIndex(index) {
  const stage = stages[index];
  sessionStorage.setItem("currentStage", stage);
  markCompletedStages(index);
}

async function startNewTest() {
  const testTypeIndex = localStorage.getItem('testType');
  showLoadingOverlay('Начинаем тест')
  try {
    const response = await sendTestType(testTypeIndex);

    if (response !== true) {
      alert('Ошибка: Некорректный результат запроса.');
    } else {
      return response
    }
  } catch (error) {
  } finally {
    hideLoadingOverlay()
  }
}

function moveToNextStage() {
  const currentIndex = getCurrentStageIndex();
  if (currentIndex < stages.length - 1) {
    setCurrentStageIndex(currentIndex + 1);
  }
}

function canAccessStage(requestedStage) {
  const requestedIndex = stages.indexOf(requestedStage);
  return requestedIndex <= getCurrentStageIndex();
}

function initPage() {
  const currentPage = window.location.pathname.split('/').pop();

  if (!canAccessStage(currentPage)) {
    redirectToPageWithParams(stages[getCurrentStageIndex()], 'test_type');
  }

  markCompletedStages(getCurrentStageIndex());
}

function markCompletedStages(currentIndex) {
  sideLinks.forEach((link, index) => {
    if (index < currentIndex) {
      link.classList.add("is-done");
    } else {
      link.setAttribute('href', '')
      link.classList.remove("is-done");
      link.addEventListener('click', (evt) => {
        evt.preventDefault();
        alert('Вы не можете получить доступ к этой странице, так как не прошли предыдущий этап.')
      })
    }
  });
}

export { startNewTest, moveToNextStage, initPage };
